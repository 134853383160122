import { PageProps as GatsbyPageProps } from 'gatsby';
import { FC } from 'react';
import { SEO } from '~/components/wrappers';
import { CMSData } from '~/config';
import { GlobalsContext, SiteMetadataContext } from '~/context/GlobalsContext';
import { navigateSafe } from '~/utils';

type ResourceRedirectTemplateProps = GatsbyPageProps<
  object,
  { page: CMSData.PageBase; resource: CMSData.Resource; globals; siteMetadata }
>;

/**
 * Resource redirect pages are generated using this template in `gatsby-node.js`.
 * This may also be a starting point for future resource landing pages
 * The reason why this is done on the client-side in this fashion is to get around
 * LinkedIn's behavior of following server-level redirects and stripping querystrings
 * when URLs are shared on the platform. In other words, without this, even if you
 * shared /r/some-resource LinkedIn would still send people to /resources.
 * https://itsyall.monday.com/boards/988860595/views/18628176/pulses/2358935459
 */
const ResourceRedirectTemplate: FC<ResourceRedirectTemplateProps> = ({
  pageContext,
}) => {
  navigateSafe(`/resources/?download=${pageContext.resource.resource_slug}`);

  return (
    <GlobalsContext.Provider value={pageContext.globals}>
      <SiteMetadataContext.Provider value={pageContext.siteMetadata}>
        <SEO
          title={pageContext.page.title}
          description={pageContext.page.description}
          keywords={pageContext.page.keywords}
          path={`/r/${pageContext.resource.resource_slug}/`}
        />
      </SiteMetadataContext.Provider>
    </GlobalsContext.Provider>
  );
};

export default ResourceRedirectTemplate;
